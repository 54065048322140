import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Link,
  List,
  ListIcon,
  ListItem,
  Menu,
  MenuButton,
  Tooltip,
  Text,
  useColorMode,
  Divider,
  useBoolean,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  FormControl,
  Input,
  FormLabel,
  PinInput,
  PinInputField,
  FormErrorMessage,
  FormHelperText,
  HStack,
  VStack,
  Collapse,
  ModalCloseButton,
  Icon,
  Spinner,
  Stack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  Img,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import {
  faBadge,
  faBadgeCheck,
  faCheck,
  faMoon,
  faMoonStars,
  faSun,
} from "@fortawesome/pro-solid-svg-icons";
import { Formik, Field, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import {
  CheckCircle,
  Circle,
  Info,
  Moon,
  Question,
  Sun,
  Waves,
} from "phosphor-react";
import YEmpowered from "../public/images/younionShieldDark.svg";
import StripeBadgeAsset from "../public/images/stripeBadgeAsset.svg";
import MicrosoftBadgeAsset from "../public/images/microsoftBadgeAsset.svg";
import AppStoreBadge from "../public/images/appStoreBadge.svg";
import GPlayBadge from "../public/images/gPlayBadge.svg";
import { useRouter } from "next/router";
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import PlanAreaIcon from "../public/images/check-setting.svg";
import { useRecoilState } from "recoil";
import currentUserInfoState, {
  currentOnboardingStepState,
} from "../recoilStates";
import * as yup from "yup";
import signUpBGSVG from "../public/images/shieldBgSignUpAsset.svg";
import signUpVan from "../public/images/signUpVanAsset 2.svg";
import Head from "next/head";
import NextLink from "next/link";
import useSWR from "swr";
import adze from "adze";
const MotionBox = motion(Box);
import { gql, GraphQLClient } from "graphql-request";
import { useBusinessCreation } from "../utils/tools";
const gqlClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_STRAPI_PREFIX}/graphql`
);
export const getServerSideProps = async (ctx: any) => {
  gqlClient.setHeader("Authorization", `Bearer ${process.env.STRAPI_API_KEY}`);
  let data = {} as any;
  if (ctx.query.partnerCode) {
    data = await gqlClient.request(
      gql`
        query ($partnerCode: String, $id: ID) {
          partners(
            filters: { partnerCode: { eq: $partnerCode }, id: { eq: $id } }
          ) {
            data {
              id
              attributes {
                businessId
                businesses {
                  data {
                    id
                    attributes {
                      businessId
                      organizationName
                      locationId
                    }
                  }
                }
                active
                organizationName
                subscriptionId
                createdAt
                partnerCode
                firstLogin
              }
            }
          }
        }
      `,
      {
        partnerCode: ctx.query.partnerCode,
      }
    );
    if (data.partners.data.length === 0 || data.partners.data.length > 1) {
      data = {};
    } else {
      data = data.partners.data[0];
    }
  }
  return {
    props: {
      partnerData: data,
    },
  };
};

const HomePage = ({ partnerData }: { partnerData: any }) => {
  const router = useRouter();
  const { colorMode, toggleColorMode } = useColorMode();
  const businessCreation = useBusinessCreation();
  useEffect(() => {
    if (partnerData?.attributes?.partnerCode) {
      businessCreation.setBusinessCreationInfo({
        ...businessCreation.businessCreationInfo,
        connectToId: partnerData.id,
      });
    }
  }, []);
  console.log(partnerData);
  return (
    <Flex flexDir="column" minWidth="100vw" minH="100vh" overflowX="hidden">
      <Head>
        <title>Econic - Join Now</title>
      </Head>
      <Flex
        bg={colorMode == "dark" ? "brand.800" : "#D0D2D3"}
        w="full"
        shadow="xl"
        minH="100px"
        align="center"
        px="12"
        py="2"
        position="fixed"
        justify="space-between"
        zIndex={100}
      >
        <Link href="https://www.econicNetwork.com">
          <Img
            w={32}
            src={
              colorMode == "dark"
                ? "/blueWhiteEconic.svg"
                : "/blueBlackEconic.svg"
            }
          />
        </Link>
        <Flex align="center" gap="5">
          <NextLink href="https://dashboard.econicNetwork.com">
            <Button
              size="lg"
              display={["none", null, "flex"]}
              variant="link"
              color="currentcolor"
            >
              Seller Dashboard
            </Button>
          </NextLink>
          <Tooltip label="Change Theme">
            <IconButton aria-label="theme" size="lg" onClick={toggleColorMode}>
              {colorMode == "light" ? (
                <Sun size={35} weight="fill" />
              ) : (
                <Moon size={35} weight="fill" />
              )}
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>
      <Flex minH="92vh" mt="80px" flexDir={["column", null, null, "row"]}>
        <Flex
          flexDir="column"
          justify="center"
          // p={["5", null, null, "2"]}
          px={["5", null, null, "20"]}
          pb={["10", null, "24"]}
          pt={["10"]}
          w={["100%", null, null, "50%"]}
          maxW={["100%", null, null, "50%"]}
          align="center"
          gap={["10", null, null, "20"]}
        >
          <Heading
            size="2xl"
            lineHeight="1.5"
            textAlign={["center", null, null, "left"]}
          >
            {"The ONLY all-in-one digital solution for local businesses"}
          </Heading>
          <List
            fontSize={26}
            alignSelf={["center", null, null, null, "flex-start"]}
            spacing={8}
          >
            <ListItem>
              <ListIcon color="brand.500" fontSize={30}>
                <FontAwesomeIcon icon={faBadgeCheck} />
              </ListIcon>
              Make more money on every transaction
            </ListItem>
            <ListItem>
              <ListIcon color="brand.500" fontSize={30}>
                <FontAwesomeIcon icon={faBadgeCheck} />
              </ListIcon>
              improve your local fundraising campaign
            </ListItem>
            <ListItem>
              <ListIcon color="brand.500" fontSize={30}>
                <FontAwesomeIcon icon={faBadgeCheck} />
              </ListIcon>
              Learn how to generate value for your business
            </ListItem>
            <ListItem>
              <ListIcon color="brand.500" fontSize={30}>
                <FontAwesomeIcon icon={faBadgeCheck} />
              </ListIcon>
              A plan for any local size business
            </ListItem>
          </List>
        </Flex>
        <Flex
          maxW={["100%", null, null, "50%"]}
          // maxH="full"
          w={["100%", null, null, "50%"]}
          // p="10"
          align="center"
          py="8"
          justify="center"
          backgroundColor={colorMode == "dark" ? "brand.1000" : "#D0D2D3"}
          position="relative"
          overflow="hidden"
          // minH={[500, null, null, "100%"]}
        >
          <SignUpFormArea />
        </Flex>
      </Flex>
      <Flex
        mt="auto"
        minH="110px"
        px={[0, null, 20, "20"]}
        align="center"
        py={[5, null, null, 0]}
        w="full"
        maxW="100vw"
        // justify="flex-start"
        justify="space-between"
        flexDir={["column", null, null, "row"]}
        gap={[5, null, null, 0]}
        bg={colorMode == "dark" ? "brand.800" : "#D0D2D3"}
      >
        <Flex
          align="center"
          gap="5"
          whiteSpace="nowrap"
          flexDir={["column", null, null, "row"]}
        >
          <Heading fontSize={["lg", null, null, "xl"]}>Our Partners:</Heading>
          <Flex gap="10">
            <Box
              bg="#32325D"
              borderRadius="lg"
              p="1"
              width={["24", null, null, "32"]}
            >
              <StripeBadgeAsset style={{ width: "100%" }} />
            </Box>
            <Box width={["24", null, null, "32"]}>
              <MicrosoftBadgeAsset style={{ width: "100%" }} />
            </Box>
          </Flex>
        </Flex>
        <Flex align="center" gap="4" flexDir={["column", null, null, "row"]}>
          <Heading fontSize={["lg", null, null, "xl"]}>
            Econic Network™ App:
          </Heading>
          <Flex gap="4">
            <Box
              shadow="dark-lg"
              borderRadius="lg"
              cursor="pointer"
              onClick={() =>
                router.push(
                  "https://apps.apple.com/us/app/revyvvnet/id1563560036"
                )
              }
            >
              <AppStoreBadge height={40} />
            </Box>
            <Box
              cursor="pointer"
              onClick={() =>
                router.push(
                  "https://play.google.com/store/apps/details?id=com.emprco.revyvvnet1&hl=en_US&gl=US"
                )
              }
            >
              <GPlayBadge height={40} width={140} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HomePage;

const SignUpFormArea = (props: any) => {
  const [PINInput, setPINInput] = useState("");
  const router = useRouter();
  const [updateClicked, setUpdateClicked] = useBoolean();
  const [continueProgressClicked, setContinueProgressClicked] = useBoolean();
  const [verifyLoading, setVerifyLoading] = useBoolean();
  const [currentUserData, setCurrentUserData] =
    useRecoilState(currentUserInfoState);
  const [referralCode, setReferralCode] = useState("");
  const [isLoading, setIsLoading] = useBoolean();
  const [activeStep, setActiveStep] = useRecoilState(
    currentOnboardingStepState
  );
  const {
    isOpen: modalOpen,
    onClose: closeModal,
    onOpen: openModal,
  } = useDisclosure();
  const {
    isOpen: webDialogOpen,
    onClose: closeWebDialog,
    onOpen: openWebDialog,
  } = useDisclosure();
  const toast = useToast();
  const signUpSchema = yup.object().shape({
    firstName: yup.string().min(1).required("First Name Required"),
    lastName: yup.string().min(1).required("Last Name Required"),
    email: yup.string().email("Invalid email").required("Email Required"),
    phone: yup
      .string()
      .matches(/^\d+$/, "Phone number must be a number")
      .max(10, "Too Long!")
      .min(10)
      .required("Phone Number Required"),
  });
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (router.query) {
      setReferralCode(router.query.referral as string);
    }
  }, [router]);
  const handleSubmit = async (values: any, partnerData?: any) => {
    setIsLoading.on();
    checkIfOnboardingStarted(values.email, values.phone)
      .then(async (res: any) => {
        if (res.data[0].ConnectedAccountId) {
          setCurrentUserData({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            CAID: res.data.ConnectedAccountId,
          });

          const webstore = await axios.get(
            `/api/findWebstore?businessId=${res.data[0].PersonId}`
          );

          if (webstore.data.landingPages.data.length) {
            openWebDialog();
          } else {
            setIsLoading.off();
            openModal();
          }
        } else {
          setCurrentUserData({
            ...values,
            pin: PINInput,
            businessId: res.data[0].PersonId,
            partnerCode: referralCode,
          });
          setActiveStep(0);

          router.push("/create-store");
        }
      })
      .catch(async (err: any) => {
        const personData = await axios.post("/api/createJTYPerson", {
          GUID: "S",
          Active: false,
          PaymentGatewayMode:
            process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
              ? "live"
              : "test",
          OnBoardingCompletion: "NotStarted",
          PersonId: 0,
          FirstName: values.firstName,
          LastName: values.lastName,
          Email: values.email,
          Phone: values.phone,
          PasswordString64: PINInput,
          Password: "",
          PasswordSalt: "",
        });
        setCurrentUserData({
          ...values,
          pin: PINInput,
          businessId: personData.data.Data.PersonId,
          partnerCode: referralCode,
        });

        setActiveStep(0);
        router.push("/create-store");
      });
  };

  const redirectToUpdateStripe = async (CAID?: any) => {
    const data = await axios
      .get("/api/stripeAccountLink", {
        params: {
          account: CAID,
          accountLinkType: "account_update",
        },
      })
      .then((response: any) => {
        router.push(response.data.url);
      });
  };

  const businessCreation = useBusinessCreation();

  const leastDestructiveRef = useRef<any>(null);
  return (
    <>
      <AlertDialog
        isOpen={webDialogOpen}
        leastDestructiveRef={leastDestructiveRef}
        onClose={closeWebDialog}
        size="lg"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody>
              <Text fontSize="xl" as="span">
                There is already an account associated with this email address
                that has set up a web store. Please visit the{" "}
                <Link
                  href="https://dashboard.econicnetwork.com"
                  color="brand.500"
                >
                  Seller dashboard
                </Link>{" "}
                to update your store.
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={closeWebDialog}>Cancel</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={modalOpen} onClose={closeModal} size="3xl">
        <ModalOverlay></ModalOverlay>
        <ModalContent alignItems="center" p="10">
          <ModalHeader>This Account Already Exists!</ModalHeader>
          <ModalCloseButton size="lg" />
          <ModalBody>
            <Flex flexDir="column" gap="8" alignItems="center">
              <Text>What would you like to do?</Text>
              <Flex
                alignItems="center"
                gap="5"
                flexDir={["column", null, null, "row"]}
              >
                <Button
                  color="white"
                  bgColor="#635BFF"
                  colorScheme="purple"
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  onClick={() => {
                    setUpdateClicked.on();
                    setContinueProgressClicked.off();
                  }}
                >
                  Update Your Stripe Details
                </Button>
                <Text>Or</Text>
                <Button
                  size="lg"
                  bgColor="brand.500"
                  colorScheme="brand"
                  color="white"
                  onClick={() => {
                    setUpdateClicked.off();
                    setContinueProgressClicked.on();
                  }}
                >
                  Continue Setting up your store
                </Button>
              </Flex>
              <Collapse in={updateClicked || continueProgressClicked}>
                <Flex flexDir="column" alignItems="center">
                  <FormLabel>Verify Your PIN</FormLabel>
                  <HStack mt="2">
                    <PinInput
                      size="lg"
                      focusBorderColor="brand.500"
                      onComplete={(value: string) => setPINInput(value)}
                    >
                      <PinInputField></PinInputField>
                      <PinInputField></PinInputField>
                      <PinInputField></PinInputField>
                      <PinInputField></PinInputField>
                    </PinInput>
                  </HStack>
                  <Button
                    colorScheme="jty"
                    bgColor="brand.500"
                    color="white"
                    width="full"
                    isLoading={verifyLoading}
                    mt="5"
                    onClick={() => {
                      setVerifyLoading.on();
                      checkIfOnboardingStarted(
                        currentUserData.email,
                        currentUserData.phone
                      ).then((res: any) => {
                        axios
                          .get(
                            `/api/getLocationData?businessId=${res.data[0].PersonId}`
                          )
                          .then((loc) => {
                            if (
                              loc.data?.Data &&
                              continueProgressClicked &&
                              res.data[0].PasswordString64 == PINInput
                            ) {
                              setCurrentUserData({
                                email: res.data[0].Email,
                                firstName: res.data[0].FirstName,
                                lastName: res.data[0].LastName,
                                phone: res.data[0].Phone,
                                CAID: res.data[0].ConnectedAccountId,
                                businessId: res.data[0].PersonId,
                                locationId: loc.data.Data.LocationId,
                              });
                              setActiveStep(6);
                              router.push("/create-store");
                            } else {
                              setCurrentUserData({
                                email: res.data[0].Email,
                                firstName: res.data[0].FirstName,
                                lastName: res.data[0].LastName,
                                phone: res.data[0].Phone,
                                CAID: res.data[0].ConnectedAccountId,
                                businessId: res.data[0].PersonId,
                              });
                            }
                          })
                          .catch((err: any) => {
                            setCurrentUserData({
                              email: res.data[0].Email,
                              firstName: res.data[0].FirstName,
                              lastName: res.data[0].LastName,
                              phone: res.data[0].Phone,
                              CAID: res.data[0].ConnectedAccountId,
                              businessId: res.data[0].PersonId,
                            });
                          });

                        if (res.data[0].PasswordString64 == PINInput) {
                          if (updateClicked) {
                            redirectToUpdateStripe(
                              res.data[0].ConnectedAccountId
                            );
                          } else if (continueProgressClicked) {
                            setActiveStep(1);
                            router.push("/create-store");
                          }
                        } else {
                          toast({
                            title: "Incorrect PIN, please try again",
                            isClosable: true,
                            status: "error",
                            duration: 3000,
                            position: "top",
                          });
                          setVerifyLoading.off();
                        }
                      });
                    }}
                  >
                    Verify
                  </Button>
                </Flex>
              </Collapse>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        }}
        onSubmit={async (values: any, actions: any) => {
          if (PINInput.length > 3) {
            actions.setSubmitting(false);
            //*Check for partner code entered
            if (referralCode) {
              const partnerData = await axios.get(`/api/partnerCodeCheck`, {
                params: {
                  partnerCode: referralCode.toUpperCase(),
                },
              });

              console.log(partnerData);

              businessCreation.setBusinessCreationInfo({
                ...businessCreation.businessCreationInfo,
                connectToId: partnerData.data.id,
              });

              if (partnerData.status === 200) {
                handleSubmit(values, { ...partnerData.data });
              } else {
                toast({
                  title: "Invalid Partner Code",
                  isClosable: true,
                  status: "error",
                  duration: 3000,
                  position: "top",
                });
              }
            } else {
              handleSubmit(values);
            }
          } else {
            toast({
              title: "PIN not entered",
              description: "Please enter a valid 4 digit PIN and try again",
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "bottom-right",
            });
          }
        }}
        validationSchema={signUpSchema}
      >
        {(props) => (
          <Form>
            <VStack gap="3" px={[5, null, 0]} h="110%">
              <Field name="firstName">
                {({ field, form }: any) => (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.firstName && form.touched.firstName}
                  >
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <Input
                      border="2px"
                      data-testid="form-element"
                      {...field}
                      data-cy="nameBox"
                      variant="filled"
                      focusBorderColor="brand.500"
                      value={field.value}
                    ></Input>
                    <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="lastName">
                {({ field, form }: any) => (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.lastName && form.touched.lastName}
                  >
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <Input
                      border="2px"
                      data-cy="lNameBox"
                      variant="filled"
                      {...field}
                      focusBorderColor="brand.500"
                      value={field.value}
                    ></Input>
                    <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="email">
                {({ field, form }: any) => (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel>Your Email</FormLabel>
                    <Input
                      border="2px"
                      data-cy="emailBox"
                      variant="filled"
                      {...field}
                      value={field.value}
                      focusBorderColor="brand.500"
                    ></Input>
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="phone">
                {({ field, form }: any) => (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.phone && form.touched.phone}
                  >
                    <FormLabel>Your Phone</FormLabel>
                    <Input
                      data-cy="phoneBox"
                      border="2px"
                      as={InputMask}
                      mask="(999)-999-9999"
                      variant="filled"
                      focusBorderColor="brand.500"
                      {...field}
                      onChange={(e: any) => {
                        form.setFieldValue(
                          "phone",
                          e.target.value
                            .replace(/\)/g, "")
                            .replace(/\(/g, "")
                            .replace(/-/g, "")
                            .replace(/ /g, "")
                            .replace(/_/g, "")
                        );
                      }}
                    ></Input>
                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <FormControl isRequired>
                <FormLabel>Create a PIN</FormLabel>

                <HStack>
                  <PinInput
                    size="lg"
                    variant="filled"
                    focusBorderColor="brand.500"
                    onComplete={(value: any) => setPINInput(value)}
                  >
                    <PinInputField data-cy="pinBox"></PinInputField>
                    <PinInputField></PinInputField>
                    <PinInputField></PinInputField>
                    <PinInputField></PinInputField>
                  </PinInput>
                </HStack>
                <FormHelperText color={colorMode == "dark" ? "white" : "black"}>
                  Save this PIN Code. You will need it to access your seller
                  dashboard
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">
                  Reffered by a partner? Enter the partner code below.
                </FormLabel>
                <Input
                  border="2px"
                  size="md"
                  variant="filled"
                  isDisabled={router?.query?.referral !== undefined}
                  focusBorderColor="brand.500"
                  onChange={(e) => setReferralCode(e.target.value)}
                  value={referralCode}
                ></Input>
              </FormControl>

              <Button
                isLoading={isLoading}
                color="white"
                type="submit"
                colorScheme="orange"
                minW={200}
                bg="brand.orange"
                size="lg"
              >
                Sign Up
              </Button>

              <Link _hover={{ color: "brand.500" }} href="/scan-qr">
                Are you a buyer?
              </Link>
            </VStack>
          </Form>
        )}
      </Formik>

      <Link
        href="https://content.econicnetwork.com/tutorial-topics/web-store-sign-up/"
        target="_blank"
      >
        <Icon
          _hover={{ color: "brand.500" }}
          _activeLink={{ color: "brand.500" }}
          as={Question}
          fontSize="3xl"
          position="absolute"
          bottom={25}
          right={50}
        ></Icon>
      </Link>
    </>
  );
};

const checkIfOnboardingStarted = async (email: any, phone: any) => {
  const data = await axios.get(`/api/getPersonByEmail`, {
    params: { email, phone },
  });
  return data;
};

const MotionFlex = motion(Flex);
